import React from 'react';

const AppContext = React.createContext();

/**
 * AppProvider
 * @component
 */
export const AppProvider = props => {
  const [searchFieldValue, setSearchFieldValue] = React.useState('');
  const value = {
    searchFieldValue,
    setSearchFieldValue
  };
  return <AppContext.Provider {...props} value={value} />;
};

export const useApp = () => {
  const context = React.useContext(AppContext);
  if (!context) throw new Error('AppContext must be in AppProvider');
  return context;
};

export default AppProvider;
