import React from 'react';

import CustomerContainer from './CustomerContainer';
import { CustomerProvider } from './contexts/customContext';

const CustomerApp = () => {
  return (
    <CustomerProvider>
      <CustomerContainer />
    </CustomerProvider>
  );
}

export default CustomerApp;
