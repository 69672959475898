import React, { useEffect } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import SidebarFunnel, { SideItemFunnel } from '../../components/Sidebar';
import { useNavigation } from '../../contexts/NavigationContext';

/**
 * SidebarContainer
 * @container
 * @component
 *
 */
const SidebarContainer = () => {
  const { themeColors } = useTheme();
  const { adminRights } = useUser();
  const { menu, setMenu } = useNavigation();

  const linkInfo = [
    {
      name: 'funnel',
      svg: 'funnel',
      subtitle: 'Administration et création des funnels',
      surname: 'funnels',
      enabled: adminRights?.funnel?.funnel?.read || false
    },
    {
      name: 'mediaDS',
      svg: 'mediaDs',
      subtitle: 'Configuration des régies pour la DS',
      surname: 'régies',
      enabled: adminRights?.funnel?.medias?.read || false
    },
    {
      name: 'schema',
      svg: 'menuList',
      subtitle: 'Paramétrer vos modèles',
      surname: 'Modèles de funnel',
      enabled: adminRights?.funnel?.funnelStep?.read || false
    },
    {
      name: 'schemaStep',
      svg: 'setting',
      subtitle: 'Paramétrer les étapes pour les modèles',
      surname: 'Étapes de funnel',
      enabled: adminRights?.funnel?.funnelStep?.read || false
    },
    {
      name: 'yougovMetrics',
      svg: 'setting',
      subtitle: 'Paramêtré vos metric yougov',
      surname: 'Metric Yougov',
      enabled: adminRights?.funnel?.yougovMetrics?.read || false
    },
    {
      name: 'export',
      svg: 'export',
      subtitle: 'Rapports complets',
      surname: 'export Excel',
      enabled: adminRights?.funnel?.backOfficeCsvExport?.read || false
    },
    {
      name: 'properties',
      svg: 'setting',
      subtitle: '',
      surname: 'Propriétés',
      enabled: adminRights?.funnel?.properties?.read || false
    },
    {
      name: 'anomalies',
      svg: 'setting',
      subtitle: 'Définissez les boundaries de votre funnel',
      surname: 'Anomalies',
      enabled: adminRights?.funnel?.anomalies?.read || false
    },
    {
      name: 'connectorConfig',
      svg: 'connecteursFunnel',
      subtitle: 'Configuration des connecteurs',
      surname: 'Connecteurs',
      enabled: adminRights?.funnel?.connectors?.read || false
    },
    {
      name: 'dataFeedback',
      svg: 'setting',
      subtitle: 'Configuration du module',
      surname: 'Boucle retour data',
      enabled: adminRights?.funnel?.dataFeedback?.read || false
    },
    {
      name: 'followBudget',
      svg: 'euro',
      subtitle: 'Paramétrage des alertes mail',
      surname: 'Suivi budgétaire',
      enabled: adminRights?.funnel?.alert?.read || false
    }
  ];

  const updateMenu = linkName => {
    // Set menu only if it's different by actual menu
    if (menu !== linkName) {
      setMenu(linkName);
    }
  };

  useEffect(() => {
    updateMenu(linkInfo[0]);
    // eslint-disable-next-line
  }, []);

  return (
    <SidebarFunnel>
      {linkInfo.map(link => {
        return (
          <SideItemFunnel
            key={link.name}
            active={link.name === menu.name}
            svg={link.svg}
            svgAttrs={{
              fill: themeColors.icon,
              dataStroke: themeColors.icon,
              stroke: themeColors.icon
            }}
            subtitle={link.subtitle}
            title={link.surname.toUpperCase()}
            onClick={() => updateMenu(link)}
            hasActiveClass={link.name === menu.name}
          />
        );
      })}
    </SidebarFunnel>
  );
};

export default SidebarContainer;
