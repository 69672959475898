import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { TextField } from 'components/Inputs';
import { Container } from 'components/Layout';

export const EditableTable = ({ headers, values, handleChange }) => {
  return (
    <Container>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow>
              {headers.map(header => {
                return (
                  <TableCell
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    {' '}
                    {header.label}{' '}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {values.map((row, i) => (
              <TableRow key={row.id}>
                {headers.map(header => {
                  if (header.editable)
                    return (
                      <TableCell>
                        <TextField
                          value={row[header.key]}
                          onChange={e => handleChange(e, i, header.key)}
                        />
                      </TableCell>
                    );
                  return (
                    <TableCell
                      style={{
                        fontWeight: 'bold'
                      }}
                    >
                      {' '}
                      {row[header.key]}{' '}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default EditableTable;
