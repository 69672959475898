import parser from 'cookie';
import Cookie from 'js-cookie';
import xlsx from 'json-as-xlsx';

import { months } from 'utils/constants';
import { ACTIVATION } from 'utils/rest';

const { FullMonthFr, ShortMonthEn } = months;
// import moment from 'utils/moment';

export function isErrorPhoneNumber(phoneNum) {
  // Regex that match only valid format
  // Valid Format:
  // (123) 456-7890
  // (123)456-7890
  // 123-456-7890
  // 123.456.7890
  // 1234567890
  // +33102030405
  // 0102030405
  // 075-63546725
  const regexNumValid = new RegExp(
    '^[\\+]?[0-9]?[\\s]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$'
  );
  // .test() return true if match
  return !regexNumValid.test(phoneNum);
}
export const formatFilename = filename => {
  return filename.split(' ').join('_');
};
export const isValidEmail = email => {
  // Regex that match only valid format
  // Valid Format:
  // username@domain.com
  const regexEmail = /^(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,4}$/;
  // .test() return true if match
  return regexEmail.test(email);
};
export const roundTwoDecimals = number => {
  return Math.round(number * 100) / 100;
};

export const calculPercent = (total, number) => {
  return Math.round((number * 100) / total);
};

export function formatNumber(
  number,
  minimumFractionDigits = 2,
  maximumFractionDigits = minimumFractionDigits
) {
  if (number === Infinity) {
    return 'N/A';
  }
  try {
    const floatValue = parseFloat(number);
    if (Number.isNaN(floatValue)) return number;
    return floatValue
      .toLocaleString('en-gb', { maximumFractionDigits, minimumFractionDigits })
      .replace(/,/g, ' ');
  } catch (e) {
    return number;
  }
}

export async function fetchFromCookie(req, options = {}) {
  return key => {
    if (req) {
      // server
      const cookies = req.headers.cookie;
      if (!cookies) return null;
      const cookieMap = parser.parse(cookies, options);
      return cookieMap[key];
    }
    // browser
    return Cookie.get(key);
  };
}

/**
 * isEmptyObject
 * @param {Object} obj
 * @desc check if an object is really empty eq = {}
 * @return Boolean
 *
 */
export function isEmptyObject(obj) {
  return obj && !Object.values(obj).length;
}

export const exportCSVFile = (items, fileName) => {
  const convertToCSV = objArray => {
    const array =
      typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i += 1) {
      let line = '';
      // eslint-disable-next-line guard-for-in
      for (const index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }

      str += `${line}\r\n`;
    }

    return str;
  };

  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);

  const csv = convertToCSV(jsonObject);

  const exportedFilenmae = fileName ? `${fileName}.csv` : 'export.csv';

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const exportXlsxFile = (data, fileName) => {
  const settings = {
    fileName, // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
  };

  xlsx(data, settings);
};

/**
 * Waits a number of ms
 *
 * @export
 * @param {number} time - sleep time in ms
 * @returns
 */
export const sleep = async time => {
  return new Promise(resolve => setTimeout(resolve, time));
};

export const shortMonthToFull = month => {
  const indexMonth = ShortMonthEn.indexOf(month);
  return FullMonthFr[indexMonth];
};

export const fullMonthToShort = month => {
  const indexMonth = FullMonthFr.indexOf(month);
  return ShortMonthEn[indexMonth];
};
export const downloadCreative = async id => {
  // eslint-disable-next-line consistent-return
  return new Promise(async resolve => {
    const datas = await ACTIVATION.downloadCreatives(id);
    const uploadedAsset = await ACTIVATION.getUploadedAsset(id);
    if (!datas || !uploadedAsset) {
      return resolve();
    }
    // eslint-disable-next-line no-undef-init
    let file = undefined;
    const contentType = datas.headers['content-type'];
    const blob =
      datas.data instanceof Blob
        ? datas.data
        : new Blob([datas.data], {
            type: contentType
          });
    if (contentType.includes('image') || contentType.includes('octet-stream')) {
      const reader = new FileReader();
      reader.onload = async e => {
        file = e.target;
        file.type = contentType;
        file.blob = blob;
        file.height = uploadedAsset.height;
        file.width = uploadedAsset.width;
        file.id = id;
        resolve(file);
      };
      await reader.readAsDataURL(blob);
    } else {
      resolve();
    }
  });
};

export const getTabObjective = [
  {
    key: 'vsw',
    value: 'visites sur site web'
  },
  {
    key: 'vl',
    value: 'ventes en ligne'
  },
  {
    key: 'vem',
    value: 'visites en magasin'
  },
  {
    key: 'vo',
    value: 'ventes omnicanales'
  },
  {
    key: 'li',
    value: 'leads ou inscrits'
  },
  {
    key: 'dam',
    value: 'Downloads application mobile'
  },
  {
    key: 'vm',
    value: 'ventes en magasin'
  },
  {
    key: 'noto',
    value: 'notoriété'
  }
];

export const objectiveCorrespondance = objective => {
  const tabObjective = getTabObjective;
  const response =
    tabObjective.find(elem => elem.key === objective)?.value || null;
  return response !== null ? response : 'N/C';
};

export const serializePlatformAccount = elem => {
  return {
    name: elem.name,
    model: elem.model,
    config: {
      connexionId: elem.connexionId?.value || elem.connexionId || undefined,
      externalId: elem.externalId
    },
    status: elem.status
  };
};

export const serializePlatformPage = elem => {
  return {
    name: elem.name,
    media: elem.media,
    config: {
      connexionId: elem.connexionId?.value || elem.connexionId || undefined,
      externalId: elem.externalId
    },
    status: elem.status
  };
};

export const removeDuplicates = array => {
  const uniqueSet = new Set(array);
  return [...uniqueSet];
};

export const activateAllRights = rights => {
  const activatedRights = {};
  Object.keys(rights).forEach(masterKey => {
    if (typeof rights[masterKey] === 'object' && rights[masterKey] !== null) {
      activatedRights[masterKey] = {};
      Object.keys(rights[masterKey]).forEach(subKey => {
        if (
          typeof rights[masterKey][subKey] === 'object' &&
          rights[masterKey][subKey] !== null
        ) {
          activatedRights[masterKey][subKey] = activateAllRights(
            rights[masterKey][subKey]
          );
        } else {
          activatedRights[masterKey][subKey] = true;
        }
      });
    } else {
      activatedRights[masterKey] = true;
    }
  });
  return activatedRights;
};

export default fetchFromCookie;
