import { api } from './api';
import { ACTIVATION, GROUP } from './';

const uri = 'stripeCustomer';

const get = async () => {
  try {
    const result = await api.get(`${uri}/domain`);
    return result.data;
  } catch (e) {
    console.error(e);
    return { ...e };
  }
};

const create = async data => {
  try {
    const result = await api.post(uri, data);
    return result.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const getSubAccounts = async id => {
  try {
    const rlt = await api.get(`${uri}/subAccounts/${id}`);
    return rlt.data;
  } catch (e) {
    return null;
  }
};

const getAllContributions = async () => {
  try {
    const allChannels = await ACTIVATION.getChannels();
    const allMedias = await ACTIVATION.getActivableMedias();
    const rlt = allChannels.concat(allMedias);
    const final = rlt.reduce((acc, r) => {
      const key = r._id;
      const label = r?.label || r?.name;
      acc.push({ key, label });
      return acc;
    }, []);
    return final;
  } catch (e) {
    return null;
  }
};

const updateCostumers = async (id, datas) => {
  try {
    const { data } = await api.put(`/${uri}/${id}`, datas);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getFinancialAdmins = async id => {
  try {
    const { data } = await api.get(`/${uri}/financialAdmins/${id}`);
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
const getBalance = async id => {
  try {
    const {
      data: { balance }
    } = await api.get(`/${uri}/balance/${id}`);
    return balance / 100;
  } catch (err) {
    console.error(err);
    return { balance: 0 };
  }
};

const getUsersByGroupid = async groupId => {
  try {
    const filtered = await api.get(`/${uri}/group/${groupId}`);
    return filtered.data;
  } catch (e) {
    return [];
  }
};

const getPresetId = async id => {
  try {
    const group = await GROUP.getGroup(id);
    return group?.presetId;
  } catch (e) {
    return [];
  }
};

const getStripeCustomerByGroupId = async (groupId) => {
  try {
    const { data } = await api.get(`/${uri}/getByGroupId/${groupId}`);
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export default {
  get,
  create,
  getSubAccounts,
  getAllContributions,
  updateCostumers,
  getFinancialAdmins,
  getUsersByGroupid,
  getPresetId,
  getBalance,
  getStripeCustomerByGroupId
};
