import React from 'react';
import { withRouter } from 'react-router-dom';

import InProgressContainer from 'containers/InProgressContainer';
import UnauthorizedContainer from 'containers/UnauthorizedContainer';

import { Container, Item, Row } from 'components/Layout';
import { Column, Grid, Switch, Wrapper } from 'components/LayoutApp';
import Visibilities from 'components/Visibilities';

import AnomaliesContainer from './containers/menus/AnomaliesContainer';
import ConnectorConfigContainer from './containers/menus/ConnectorConfigContainer';
import DataFeedBackContainer from './containers/menus/DataFeedBackContainer';
import ExportContainer from './containers/menus/ExportContainer';
import FollowBudgetContainer from './containers/menus/FollowBudgetContainer';
// MENU
import FunnelContainer from './containers/menus/FunnelContainer';
import MediaDailyRateContainer from './containers/menus/MediaDailyRateContainer';
import PropertiesContainer from './containers/menus/PropertiesContainer';
import SchemaContainer from './containers/menus/SchemaContainer';
import SchemaStepContainer from './containers/menus/SchemaStepContainer';
import YougovMetricsContainer from './containers/menus/YougovMetricsContainer';
import SidebarContainer from './containers/SidebarContainer';
import { useNavigation } from './contexts/NavigationContext';

const App = () => {
  const { menu, showMenu } = useNavigation();
  const getContainer = () => {
    const menuName = menu.name;
    if (!menu.enabled) {
      return <UnauthorizedContainer />;
    }

    switch (menuName) {
      case 'funnel':
        return <FunnelContainer />;
      case 'mediaDS':
        return <MediaDailyRateContainer />;
      case 'export':
        return <ExportContainer />;
      case 'properties':
        return <PropertiesContainer />;
      case 'connectorConfig':
        return <ConnectorConfigContainer />;
      case 'schemaStep':
        return <SchemaStepContainer />;
      case 'schema':
        return <SchemaContainer />;
      case 'yougovMetrics':
        return <YougovMetricsContainer />;
      case 'anomalies':
        return <AnomaliesContainer />;
      case 'dataFeedback':
        return <DataFeedBackContainer />;
      case 'followBudget':
        return <FollowBudgetContainer />;
      default:
        return <InProgressContainer text={menuName} />;
    }
  };

  return (
    <Wrapper>
      <Grid>
        <Column>
          <SidebarContainer />
        </Column>
        <Column verticalySpaced>
          <Visibilities>
            <Switch>
              <Container>
                <Row spacing={0}>
                  <Item
                    alignItems="flex-start"
                    show={showMenu}
                    style={{ paddingRight: '8px' }}
                  >
                    {getContainer()}
                  </Item>
                </Row>
              </Container>
            </Switch>
          </Visibilities>
        </Column>
      </Grid>
    </Wrapper>
  );
};

export default withRouter(App);
