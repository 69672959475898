import { api } from './api';

const create = async data => {
  try {
    const result = await api.post(`/domain/config`, data);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const get = async () => {
  try {
    const result = await api.get(`/domain/config`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getAll = async () => {
  try {
    const result = await api.get(`/domain/config/all`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const update = async (id, body) => {
  try {
    const { data } = await api.put(`/domain/config/${id}`, body);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteConfig = async id => {
  try {
    const result = await api.delete(`/domain/config/${id}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const sendNewsletter = async userEmails => {
  try {
    await api.post(`/domain/newsletter`, { userEmails });
    return true;
  } catch (err) {
    console.error('sendNewsletter', err);
    return false;
  }
};

const checkIsAutoLogin = async domain => {
  try {
    const result = await api.get(`/domain/config/checkIsAutoLogin/${domain}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
}

const updateAutoLogin = async (domain, actif) => {
  try {
    const result = await api.put(`/domain/config/updateAutoLogin/${domain}`, { actif });
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
}

const generateAutoLoginToken = async (domain, email) => {
  try {
    const result = await api.post(`/domain/config/generateAutoLoginToken/${domain}`, { email });
    return result.data;
  } catch (err) {
    return {};
  }
}

export default {
  create,
  update,
  get,
  getAll,
  deleteConfig,
  sendNewsletter,
  checkIsAutoLogin,
  updateAutoLogin,
  generateAutoLoginToken
};
