import React, { useEffect, useState } from 'react';
import Aigle from 'aigle';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import pluralize from 'utils/pluralize';
import { ADMINRIGHTS, DOMAINCONFIG, GROUP, USERS } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDelete } from './components/ModalDelete';
import { ModalForm } from './components/ModalForm';

const UserContainer = () => {
  const { themeColors } = useTheme();
  const { adminRights: adminRightsContext, setAdminRights, user } = useUser();

  const [groupsList, setGroups] = useState({});
  const [adminPresetList, setAdminPresetList] = useState({});
  const [roles, setRoles] = useState({});
  const [usersList, setUsers] = useState([]);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [domain, setDomain] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});

  const closeModal = () => {
    setModal(null);
    setSelectedUser({});
  };

  const selectUser = id => {
    const currentUser = usersList.find(el => el._id === id);
    currentUser.groups = groupsList.filter(
      group => !!group.userIds?.find(el => el === currentUser._id)
    );
    setSelectedUser(currentUser);
    return currentUser;
  };

  const getUsers = async () => {
    try {
      const result = await USERS.getAll();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const getDomain = async () => {
    try {
      const result = await DOMAINCONFIG.get();
      return result;
    } catch (err) {
      console.error(err);
      return {};
    }
  };
  const getGroups = async () => {
    try {
      const result = await GROUP.getGroups();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const getRoles = async () => {
    try {
      const result = await USERS.getRoles();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const getAdminRights = async () => {
    try {
      const result = await USERS.getAdminRights();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const getAdminRightsPresets = async () => {
    try {
      const result = await ADMINRIGHTS.getPresetList();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getToolTipText = txt => {
    return (
      <Row spacing={0}>
        <Item>
          <Text color={themeColors.dark}>{txt}</Text>
        </Item>
      </Row>
    );
  };

  const formatGroups = groups => {
    const nbGroups = groups.length;
    if (nbGroups === 0) {
      return <Text>Aucun</Text>;
    }
    if (nbGroups === 1)
      return <Text color="global"> {groups[0] && groups[0].name} </Text>;

    const others = nbGroups - 1;
    const str = ` ${others} ${pluralize('autre', others)} ${pluralize(
      'groupe',
      others
    )}`;

    const tooltipContent = () => {
      return (
        <Container>{groups.map(group => getToolTipText(group.name))}</Container>
      );
    };
    return (
      <Container>
        <Row spacing={2}>
          <Item flex>
            <Text color="global"> {groups[0].name}&nbsp;</Text>
          </Item>
          <Item flex>
            <Text> et&nbsp;</Text>
          </Item>
          <Item flex>
            <Tooltip title={tooltipContent()}>
              <Text color="global">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  useEffect(() => {
    const getDatas = async () => {
      const users = await getUsers();
      const groups = await getGroups();
      const rolesList = await getRoles();
      const adminRightsList = await getAdminRights();
      const adminRightsPresets = await getAdminRightsPresets();
      const currentDomain = await getDomain();

      const mapped = users.map(item => {
        const newItem = {};
        newItem._id = item._id;
        newItem.id = item._id;
        newItem.familyName = item.family_name;
        newItem.givenName = item.given_name;
        newItem.email = item.email;
        const userGroups = groups?.filter(
          group => !!group.userIds?.find(userId => userId === item._id)
        );
        newItem.groups = formatGroups(userGroups);
        newItem.groupLength = userGroups.length;
        newItem.role = item.roles[0]?.name || 'AUCUN';
        return newItem;
      });
      setUsers(
        users.map(item => {
          const newItem = item;
          newItem.adminRights = adminRightsList.find(
            el => el.userId === item._id
          );
          return newItem;
        })
      );

      const tmpRoles =
        adminRightsContext?.general?.admin?.create ||
        adminRightsContext?.general?.admin?.update
          ? rolesList
          : rolesList.filter(el => el.name !== 'ADMIN');
      setGroups(groups);
      setAdminPresetList(adminRightsPresets);
      setRoles(tmpRoles);
      setRows(mapped);
      setAllRows(mapped);
      setLoaded(true);
      setDomain(currentDomain);
    };

    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const handleCreateUser = async ({ groups, adminRights, ...datas }) => {
    const newUser = await USERS.createUser(datas);
    if (newUser) {
      await Promise.all(
        groups.map(async group => {
          GROUP.addUser({
            groupId: group._id,
            userId: newUser.externalId
          });
        })
      );
      const newRole = datas.roles.map(el => {
        return roles.find(item => item.id === el)?.name || 'unknow';
      });
      if (newRole.indexOf('ADMIN') !== -1) {
        await USERS.createAdminRights({
          userId: newUser.externalId,
          rights: adminRights
        });
      }
      setModal(null);
      setLoaded(false);
    }
  };

  const handleUpdateUser = async ({ groups, adminRights, ...datas }) => {
    await USERS.updateUser(selectedUser.graviteeId, datas);

    await Aigle.eachSeries(groupsList, async item => {
      const userInGroup =
        item.userIds?.filter(userId => userId === selectedUser._id).length !==
        0;
      if (groups.find(el => el.name === item.name)) {
        if (!userInGroup) {
          await GROUP.addUser({
            groupId: item._id,
            userId: selectedUser._id
          });
        }
      } else if (userInGroup) {
        await GROUP.deleteUser({ groupId: item._id, userId: selectedUser._id });
      }
    });

    const newRole = datas.roles.map(el => {
      return roles.find(item => item.id === el)?.name || 'unknow';
    });
    const isAdmin = newRole.indexOf('ADMIN') !== -1;
    if (isAdmin) {
      if (!selectedUser.adminRights) {
        await USERS.createAdminRights({
          userId: selectedUser._id,
          rights: adminRights
        });
      } else {
        await USERS.updateAdminRights(selectedUser.adminRights._id, {
          rights: adminRights
        });
      }
      if (selectedUser._id === user._id) {
        setAdminRights(adminRights);
      }
    }
    if (!isAdmin && selectedUser.adminRights?._id) {
      await USERS.deleteAdminRights(selectedUser.adminRights._id);
    }
    setModal(null);
    setLoaded(false);
  };

  const handleDelete = async () => {
    await USERS.deleteUser(selectedUser._id);
    await Promise.all(
      selectedUser.groups.map(async group => {
        GROUP.deleteUser({
          groupId: group._id,
          userId: selectedUser._id
        });
      })
    );
    if (selectedUser.adminRights?._id) {
      await USERS.deleteAdminRights(selectedUser.adminRights._id);
    }
    setLoaded(false);
    closeModal();
  };

  const addUser = () => {
    if (
      adminRightsContext?.general?.users?.create ||
      adminRightsContext?.general?.admin?.create
    ) {
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const updateUser = id => {
    const currentUser = selectUser(id);
    if (adminRightsContext?.general?.admin?.update) {
      return setModal('modalUpdate');
    }

    if (
      adminRightsContext?.general?.users?.update &&
      currentUser.roles[0]?.name !== 'ADMIN'
    ) {
      return setModal('modalUpdate');
    }

    return setModal('unauthorized');
  };

  const deleteUser = id => {
    const currentUser = selectUser(id);
    if (adminRightsContext?.general?.admin?.delete) {
      return setModal('modalDelete');
    }

    if (
      adminRightsContext?.general?.users?.delete &&
      currentUser.roles[0]?.name !== 'ADMIN'
    ) {
      return setModal('modalDelete');
    }

    return setModal('unauthorized');
  };

  const filterRows = newRows => {
    setRows(newRows);
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Recherche un utilisateur"
            placeholder="Id, Email, Nom, ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={addUser} text="Ajouter un utilisateur" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              onValidate={handleCreateUser}
              config={{
                rolesList: roles,
                groupsList,
                adminPresetList
              }}
              domain={domain}
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                { label: 'ID', id: 'id', sortKey: 'id' },
                { label: 'Adresse email', id: 'email', sortKey: 'email' },
                { label: 'Prénom', id: 'givenName', sortKey: 'givenName' },
                { label: 'Nom', id: 'familyName', sortKey: 'familyName' },
                { label: 'Organisation', id: 'groups', sortKey: 'groupLength' },
                { label: 'Rôle', id: 'role', sortKey: 'role' }
              ]}
              onUpdate={updateUser}
              onDelete={deleteUser}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalForm
              open={openModal === 'modalUpdate'}
              onClose={closeModal}
              onValidate={handleUpdateUser}
              rolesList={roles}
              config={{
                rolesList: roles,
                selectedUser,
                groupsList,
                adminPresetList
              }}
              domain={domain}
            />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              open={openModal === 'modalDelete'}
              onClose={closeModal}
              onDelete={handleDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default UserContainer;
