import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

export const ButtonCreate = props => {
  const { text, justify, disabled, children, buttonColor, textColor } = props;
  const { themeColors } = useTheme();
  return (
    <Container>
      <Row spacing={0}>
        <Item justify={justify}>
          <Button
            variant="contained"
            disabled={disabled}
            {...props}
            color={
              disabled ? themeColors.lightSmooth : buttonColor || undefined
            }
          >
            <Container>
              <Row spacing={1} alignItems="center">
                <Item flex>
                  <AddIcon
                    style={{ color: textColor || themeColors.keep_light }}
                  />
                </Item>
                <Item xs style={{ padding: '0px' }}>
                  <Text
                    style={{ color: textColor || themeColors.keep_light }}
                    bold={textColor}
                  >
                    {text || children || 'Ajouter'}
                  </Text>
                </Item>
              </Row>
            </Container>
          </Button>
        </Item>
      </Row>
    </Container>
  );
};

ButtonCreate.defaultProps = {
  text: null,
  justify: 'flex-end',
  disabled: false,
  children: null,
  buttonColor: null,
  textColor: null
};
ButtonCreate.propTypes = {
  onClose: PropTypes.func.isRequired,
  buttonColor: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.string,
  justify: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node
};

export default ButtonCreate;
