import React from 'react';
import { withRouter } from 'react-router-dom';

import InProgressContainer from 'containers/InProgressContainer';
import UnauthorizedContainer from 'containers/UnauthorizedContainer';

import { Container, Item, Row } from 'components/Layout';
import { Column, Grid, Switch, Wrapper } from 'components/LayoutApp';
import Visibilities from 'components/Visibilities';

import ActivableMediaContainer from './containers/menus/ActivableMediaContainer';
import ConnectorContainer from './containers/menus/ConnectorContainer';
import ConnexionContainer from './containers/menus/ConnexionContainer';
import CustomerContainer from './containers/menus/CustomerContainer';
// MENU
import DashboardContentContainer from './containers/menus/DashboardContentContainer';
import DomainContainer from './containers/menus/DomainContainer';
import GroupContainer from './containers/menus/GroupContainer';
import UserContainer from './containers/menus/UserContainer';
import SidebarContainer from './containers/SidebarContainer';
import { useNavigation } from './contexts/NavigationContext';

const App = () => {
  const { menu, showMenu } = useNavigation();
  const getContainer = () => {
    const menuName = menu.name;
    if (!menu.enabled) {
      return <UnauthorizedContainer />;
    }

    switch (menuName) {
      case 'dashboardContent':
        return <DashboardContentContainer />;
      case 'groups':
        return <GroupContainer />;
      case 'users':
        return <UserContainer />;
      case 'customers':
        return <CustomerContainer />;
      case 'connectorsConnexion':
        return <ConnexionContainer />;
      case 'connectorsConfig':
        return <ConnectorContainer />;
      case 'domain':
        return <DomainContainer />;
      case 'activableMedia':
        return <ActivableMediaContainer />;
      default:
        return <InProgressContainer text={menuName} />;
    }
  };

  return (
    <Wrapper>
      <Grid>
        <Column>
          <SidebarContainer />
        </Column>
        <Column verticalySpaced>
          <Visibilities>
            <Switch>
              <Container>
                <Row spacing={0}>
                  <Item
                    alignItems="flex-start"
                    show={showMenu}
                    style={{ paddingRight: '8px' }}
                  >
                    {getContainer()}
                  </Item>
                </Row>
              </Container>
            </Switch>
          </Visibilities>
        </Column>
      </Grid>
    </Wrapper>
  );
};

export default withRouter(App);
