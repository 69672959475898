import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import {
  ACTIVATION,
  CONNEXION,
  DATA_PROVIDERS,
  DOMAINCONFIG,
  IMPORTMODEL,
  USERS
} from 'utils/rest';

import { Text } from 'components/DataDisplay';
import Image from 'components/Image';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDelete } from '../../../components/ModalDelete';

import { ModalForm, ModalSetup } from './components';

function DomainContainer() {
  const [rows, setRows] = useState([]);
  const [domainConfigList, setDomainConfigList] = useState([]);
  const [dataProviders, setDataProviders] = useState([]);
  const [selectedDomainConfig, setSelectedDomainConfig] = useState({});

  const [presetList, setPresetList] = useState([]);
  const [adminRole, setAdminRole] = useState();
  const [openModal, setModal] = useState(null);
  const [connectorsConnexions, setConnectorsConnexions] = useState([]);
  const [importModels, setImportModels] = useState([]);

  const [loaded, setLoaded] = useState(false);

  const { adminRights, user } = useUser();
  const { themeColors } = useTheme();

  const closeModal = () => {
    setModal(null);
    setSelectedDomainConfig({});
  };
  const onDelete = () => {
    DOMAINCONFIG.deleteConfig(selectedDomainConfig._id).then(() => {
      closeModal();
      setLoaded(false);
    });
  };

  const selectDomainConfig = id => {
    const selected = domainConfigList.find(el => el._id === id);
    setSelectedDomainConfig(selected);
  };

  const getDomainConfig = async () => {
    try {
      const result = await DOMAINCONFIG.get();
      return result;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  };

  const normalizeDataProviders = providers => {
    return providers.map(provider => ({
      key: provider,
      label: provider
    }));
  };

  const getDataProviders = async () => {
    const response = await DATA_PROVIDERS.get();
    return normalizeDataProviders(response);
  };

  const getPresetList = async () => {
    try {
      const result = await ACTIVATION.getActivationPresets();
      return result;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  };

  const getBoolValue = value => {
    return value ? 'Oui' : 'Non';
  };

  const getDatas = async () => {
    const fetchedProviders = await getDataProviders();
    setDataProviders(fetchedProviders);
    const presets = await getPresetList();
    setPresetList(presets);
    const roles = await USERS.getRoles();
    setAdminRole(roles.find(el => el.name === 'ADMIN'));
    const data = await getDomainConfig();
    const connexions = await CONNEXION.getConnexions();
    const resImportModels = await IMPORTMODEL.get();
    if (data) {
      data.dataProviders = normalizeDataProviders(data?.dataProviders ?? []);
      data.excludeDataProviders = normalizeDataProviders(data?.excludeDataProviders ?? []);
      setDomainConfigList([data]);
      const mapped = {
        logo: data.logo ? (
          <Image src={data.logo} height="37px" alt={`${data.domain} logo`} />
        ) : (
          undefined
        ),
        domain: data.domain,
        features: data.features,
        name: data.name,
        id: data.id,
        _id: data._id,
        crm: getBoolValue(data.modules.crm),
        audiences: getBoolValue(data.modules.audiences),
        activation: getBoolValue(data.modules.activation),
        funnel: getBoolValue(data.modules.funnel),
        ermesLive: getBoolValue(data.modules.ermesLive)
      };
      setRows([mapped]);
    } else {
      setDomainConfigList([]);
      setRows([]);
    }
    setImportModels(
      resImportModels.map(e => ({ label: e.label, value: e.model }))
    );
    setConnectorsConnexions(
      connexions.map(e => ({ label: e.name, value: e._id }))
    );
    setLoaded(true);
  };

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const handleCreateDomainConfig = data => {
    DOMAINCONFIG.create({
      ...data,
      domain: user.domain
    }).then(() => {
      setModal(null);
      setLoaded(false);
    });
  };

  const handleSetupDomain = data => {
    DOMAINCONFIG.create(data).then(() => {
      setModal(null);
      setLoaded(false);
    });
  };

  const handleUpdateDomainConfig = async data => {
    const apis = [];
    if (data.autoLogin !== undefined) {
      apis.push(DOMAINCONFIG.updateAutoLogin(data.domain, data.autoLogin));
    }
    apis.push(
      DOMAINCONFIG.update(selectedDomainConfig._id, {
        ...data,
        domain: user.domain
      })
    );

    await Promise.all(apis);

    setModal(null);
    setLoaded(false);
  };

  const addDomainConfig = async () => {
    if (adminRights?.general?.domain?.create) {
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const setupDomain = async () => {
    if (adminRights?.general?.domain?.create) {
      setSelectedDomainConfig({});
      setModal('modalSetup');
    } else {
      setModal('unauthorized');
    }
  };

  const updateDomainConfig = async id => {
    if (adminRights?.general?.domain?.update) {
      selectDomainConfig(id);
      const selected = domainConfigList.find(f => f._id === id);
      setSelectedDomainConfig(selected);
      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const deleteDomainConfig = id => {
    if (adminRights?.general?.domain?.delete) {
      selectDomainConfig(id);
      setModal('modalDelete');
    } else {
      setModal('unauthorized');
    }
  };

  return (
    <Container>
      <Row spacing>
        <Loading loading={!loaded}>
          <Item>
            <Row spacing={5}>
              <Item justify="flex-start" xs>
                <Row justify="space-between">
                  <Button
                    variant="contained"
                    onClick={setupDomain}
                    color={themeColors.black}
                  >
                    <Container>
                      <Row spacing={1} alignItems="center">
                        <AddIcon />
                        &nbsp;
                        <Text color="inherit">Créer un nouveau domaine</Text>
                      </Row>
                    </Container>
                  </Button>
                </Row>
              </Item>
            </Row>
            <Row spacing={5}>
              {domainConfigList.length === 0 ? (
                <Item justify="center">
                  <Button
                    variant="contained"
                    onClick={addDomainConfig}
                    color={themeColors.black}
                  >
                    <Row spacing={1} alignItems="center">
                      <SettingsIcon />
                      &nbsp;
                      <Text color="inherit">
                        Configurer le domain {user.domain}
                      </Text>
                    </Row>
                  </Button>
                </Item>
              ) : (
                <Item justify="center">
                  <TableData
                    rows={rows}
                    headers={[
                      { label: 'Logo', id: 'logo', sortKey: 'logo' },
                      { label: 'Key', id: 'domain', sortKey: 'domain' },
                      { label: 'Domaine', id: 'name', sortKey: 'name' },
                      {
                        label: 'CRM',
                        id: 'crm',
                        sortKey: 'crm'
                      },
                      {
                        label: 'Audiences',
                        id: 'audiences',
                        sortKey: 'audiences'
                      },
                      {
                        label: 'Activation',
                        id: 'activation',
                        sortKey: 'activation'
                      },
                      { label: 'Funnel', id: 'funnel', sortKey: 'funnel' },
                      {
                        label: 'ERMES Live',
                        id: 'ermesLive',
                        sortKey: 'ermesLive'
                      }
                    ]}
                    hidenFields={['_id']}
                    onUpdate={updateDomainConfig}
                    onDelete={deleteDomainConfig}
                  />
                </Item>
              )}
            </Row>
          </Item>
        </Loading>
      </Row>
      {openModal === 'modalUpdate' && (
        <ModalForm
          actionName="Mettre à jour"
          open={openModal === 'modalUpdate'}
          onClose={closeModal}
          onValidate={handleUpdateDomainConfig}
          config={selectedDomainConfig}
          dataProviders={dataProviders}
          preset={presetList}
          connectorsConnexions={connectorsConnexions}
          importModels={importModels}
        />
      )}
      {openModal === 'modalDelete' && (
        <ModalDelete
          name={selectedDomainConfig.name}
          type="cette configuration de domaine"
          open={openModal === 'modalDelete'}
          onClose={closeModal}
          onDelete={onDelete}
        />
      )}
      {openModal === 'unauthorized' && (
        <UnauthorizedModal
          open={openModal === 'unauthorized'}
          onClose={closeModal}
        />
      )}
      {openModal === 'modalCreate' && (
        <ModalForm
          open={openModal === 'modalCreate'}
          actionName="Configurer"
          onClose={closeModal}
          onValidate={handleCreateDomainConfig}
          config={{}}
          connectorsConnexions={connectorsConnexions}
          importModels={importModels}
        />
      )}
      {openModal === 'modalSetup' && (
        <ModalSetup
          open={openModal === 'modalSetup'}
          actionName="Configurer"
          onClose={closeModal}
          onValidate={handleSetupDomain}
          dataProviders={dataProviders}
          presetList={presetList}
          adminRole={adminRole}
        />
      )}
    </Container>
  );
}

export default DomainContainer;
